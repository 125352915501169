import { executeApi, useApi } from '../../composables/useApi'
import { ReqExpertProfile, ReqParentProfile, ReqStudentProfile, ResProfile } from '../../openapi'
import { ref } from 'vue'
import { navigateTo, useRuntimeConfig, useRouter } from 'nuxt/app'
import defaultProfileImage from '../../assets/images/avatar.svg'

export const useMemberProfileService = () => {
  const api = useApi()
  const router = useRouter()

  const memberProfile = ref<ResProfile>({})
  const inputAddress = ref(null)

  const errorTemplate = {
    name: false,
    countryId: false,
    stateId: false,
    student: {
      schoolId: false,
      schoolName: false,
      currentGrade: false,
      graduationDate: false
    },
    parent: {
      collegeList: [
        {
          collegeId: false,
          collegeName: false,
          majorId: false,
          degreeLevel: false,
          graduationDate: false
        }
      ]
    }
  }

  const showError = ref({...errorTemplate})

  const resetError = () => {
    showError.value = {
     ...errorTemplate
    }
  }

  const fetchMemberProfile = async () => {
    await executeApi(async () => {
      const { data } = await api.myPond.getProfile()
      memberProfile.value = data
    })
  }


  const handleUploadProfileImage = async (file) => {
    console.log(file)
    let res
    await executeApi(async () => {
      const { data } = await api.myPond.uploadProfileImage({
        profileImage: file
      })
      res = data
      memberProfile.value.imageUrl = data
    })
    return res
  }

  const getProfileImage = (imageUrl) => {
    if (!imageUrl) {
      return defaultProfileImage
    }

    const config = useRuntimeConfig()
    return config.public['FILE_CDN_HOST'] + imageUrl
  }

  const getGraduationDate = (input) => {
    try {
      if(!input) {
        return ''
      }
      return `class of ${new Date(input).getFullYear()}`
    } catch (e) {
      return ''
    }
  }

  const getGraduationDateFormat = (input) => {
    if(input) {
      try {
        const date = new Date(input)

        const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' }
        let formattedDate = date.toLocaleDateString('en-US', options)

        const splitDate = formattedDate.split(' ')
        splitDate[0] = splitDate[0] + '.'
        formattedDate = splitDate.join(' ')

        return formattedDate;
      } catch (e) {
        return ''
      }
    }
  }

  return {
    memberProfile,
    inputAddress,
    showError,
    router,
    resetError,
    getProfileImage,
    getGraduationDate,
    getGraduationDateFormat,
    fetchMemberProfile,
    handleUploadProfileImage
  }
}

export const useMemberStudentProfileService = () => {
  const api = useApi()
  const {
    memberProfile,
    showError,
    resetError,
    router,
    inputAddress,
    getProfileImage,
    fetchMemberProfile,
    handleUploadProfileImage,
  } = useMemberProfileService()


  const iDonSee = ref(false)
  const inputSchoolId = ref(null)

  const inputAltSchoolName = ref(null) // 구글 플레이스 인풋 잡기 위한 ref

  const handleIDonSee = (value) => {
    iDonSee.value = value
    if (value) {
      memberProfile.value.schoolName = null
      inputSchoolId.value.inputRef.clear()
      showError.value.student.schoolId = false
    }
  }

  const handleUpdateStudentProfile = () => {
    resetError()
    let isError = false

    if (!memberProfile.value.memberName) {
      showError.value.name = true
      isError = true
    }

    if (!memberProfile.value.countryId) {
      showError.value.countryId = true
      isError = true
    }

    if (memberProfile.value.countryId === 231 && !memberProfile.value.stateId) {
      showError.value.stateId = true
      isError = true
    }

    if (!iDonSee.value && !memberProfile.value.schoolId) {
      showError.value.student.schoolId = true
      isError = true
    }

    if (iDonSee.value && !memberProfile.value.schoolName) {
      showError.value.student.schoolName = true
      isError = true
    }

    if (!memberProfile.value.currentGrade) {
      showError.value.student.currentGrade = true
      isError = true
    }


    if (!memberProfile.value.graduationDate) {
      showError.value.student.graduationDate = true
      isError = true
    }

    if (isError) {
      return
    }

    executeApi(async () => {
      await api.myPond.updateStudentProfile({
        reqStudentProfile: {
          ...memberProfile.value as ReqStudentProfile
        }
      })
      router.back()
    })
  }

  return {
    memberProfile,
    showError,
    resetError,
    getProfileImage,
    fetchMemberProfile,
    handleUploadProfileImage,
    iDonSee,
    inputSchoolId,
    inputAddress,
    inputAltSchoolName,
    handleIDonSee,
    handleUpdateStudentProfile
  }
}

export const useMemberParentProfileService = () => {
  const api = useApi()
  const {
    memberProfile,
    showError,
    resetError,
    router,
    inputAddress,
    getProfileImage,
    fetchMemberProfile,
    handleUploadProfileImage,
  } = useMemberProfileService()

  const collegeInputRefList = ref([])// parent만 쓴다


  const handleAddCollege = () => {
    memberProfile.value.resGraduateCollegeList.push({})
  }

  const handleDeleteCollege = (index) => {
    memberProfile.value.resGraduateCollegeList.splice(index, 1)
  }

  const handleUpdateParentProfile = async () => {
    resetError()
    let isError = false

    if (!memberProfile.value.memberName) {
      showError.value.name = true
      isError = true
    }

    if (!memberProfile.value.countryId) {
      showError.value.countryId = true
      isError = true
    }

    if (memberProfile.value.countryId === 231 && !memberProfile.value.stateId) {
      showError.value.stateId = true
      isError = true
    }

    memberProfile.value.resGraduateCollegeList.forEach((college, idx) => {
      const collegeError = collegeInputRefList.value[idx].hasError
      showError.value.parent.collegeList[idx] =  collegeError
      if (collegeError.isError) {{
        isError = true
      }}
    })

    if (isError) {
      return
    }

    await executeApi(async () => {
      await api.myPond.updateParentProfile({
        reqParentProfile: {
          ...memberProfile.value as ReqParentProfile,
          reqGraduateCollegeList: memberProfile.value.resGraduateCollegeList
        }
      })
      router.back()
    })
  }

  return {
    memberProfile,
    showError,
    resetError,
    inputAddress,
    getProfileImage,
    fetchMemberProfile,
    handleUploadProfileImage,
    handleUpdateParentProfile,
    collegeInputRefList,
    handleAddCollege,
    handleDeleteCollege
  }
}

export const useMemberExpertProfileService = () => {
  const api = useApi()

  const {
    memberProfile,
    showError,
    resetError,
    router,
    inputAddress,
    getProfileImage,
    fetchMemberProfile,
    handleUploadProfileImage,
  } = useMemberProfileService()

  const handleUpdateExpertProfile = () => {
    resetError()

    let isError = false

    if (!memberProfile.value.memberName) {
      showError.value.name = true
      isError = true
    }

    if (!memberProfile.value.countryId) {
      showError.value.countryId = true
      isError = true
    }

    if (memberProfile.value.countryId === 231 && !memberProfile.value.stateId) {
      showError.value.stateId = true
      isError = true
    }

    if (isError) {
      return
    }

    executeApi(async () => {
      await api.myPond.updateExpertProfile({
        reqExpertProfile: {
          ...memberProfile.value as ReqExpertProfile
        }
      })
      router.back()
    })
  }

  return {
    memberProfile,
    showError,
    resetError,
    inputAddress,
    getProfileImage,
    fetchMemberProfile,
    handleUploadProfileImage,
    handleUpdateExpertProfile
  }
}
